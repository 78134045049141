
import { CanvasPosition, PointData } from './CanvasPosition' 
import { StrokeEntity } from './modules/entity/entity-stroke';
import { MainUI } from './main';
import * as React from 'react';
import ReactDom from 'react-dom';
import { BrushTool } from '~modules/tool/tool-brush'
import { ShapeTool } from '~modules/tool/tool-shape';
import { TodoTool } from '~modules/tool/tool-todo';
import { EntityManager } from '~entity-manager';
import { MoveTool } from '~modules/tool/tool-move';
import { dataToEntity } from '~modules/entity/entity-factory';
import { StickerTool } from '~modules/tool/tool-sticker';
import { EntityBase } from '~modules/entity/entity-base';


// create canvases
const mainCanvas = document.getElementById("main-canvas") as HTMLCanvasElement;
const bufferCanvas = document.createElement('canvas');
const reserveBuffer = document.createElement('canvas');
let cached = false;

// create canvas position
const canvasPosition = new CanvasPosition(mainCanvas);


let entities : EntityManager = new EntityManager();
EntityBase.DefaultEntityManager = entities;
// take care of the ui stuff

const toolset = ["pen", "todo", "shape",  "mover", "sticker", "eraser", "sparkles"];
const toolInstances = { pen: new BrushTool(mainCanvas, canvasPosition, entities),
        todo: new TodoTool(mainCanvas, canvasPosition, entities), 
        shape: new ShapeTool(mainCanvas, canvasPosition, entities),
        mover: new MoveTool(mainCanvas, canvasPosition, entities),
        sticker: new StickerTool(mainCanvas, canvasPosition, entities)}

// temp assignment
let currentToolInstance = toolInstances.pen;

let currentTool = toolset[0];
const setTool = (toolIndex:number)=>{

    currentToolInstance.onUnequip();
    currentTool=toolset[toolIndex];
    currentToolInstance = toolInstances[currentTool]
    
    currentToolInstance.onEquip();
    doRender();
}




let currentZoom = 1.0;
const setZoom = (newZoom:number) => {

    currentZoom = Math.round(newZoom*100)/100;
    canvasPosition.setZoom(currentZoom);
    doRender();
}

const save = (sendData)=>{

    const saveObj = {entities:[]}

    entities.forEach(stroke => {
        const jsonData = stroke.toJson();
        saveObj.entities.push(jsonData);
    });

    // fire off to the server
    sendData(saveObj.entities)

    localStorage.setItem("page", JSON.stringify(saveObj))
    console.log('saveobj', saveObj)
}


// load
const load = (strokeDatum)=>{

    // console.log('strokeDatum', strokeDatum);
    if(strokeDatum) {
        entities.clear();
        strokeDatum.entities.forEach(strokeData => {
            const ent = dataToEntity(strokeData);
            if(ent) {
                ent.setId(strokeData.id);
                entities.push( ent );
            }
        });

        strokeDatum.entities.forEach(strokeData => {
            if(strokeData.relationships) {
                Object.keys(strokeData.relationships).forEach(relationshipName => {

                    const relationship = strokeData.relationships[relationshipName];
                    let ents = null;
                    
                    // if its an array loop through
                    if(Array.isArray(relationship)){
                        ents = relationship.map(id => entities.getById(id))
                    } else{
                        ents = entities.getById(relationship);
                    }
                    const entity = entities.getById(strokeData.id);
                    entity.addRelationship(relationshipName, ents)
                });
            }
        });

        entities.forEach(ent => ent.recalcBounds());
        console.log('loaded', entities)
    } else {
        console.log('skipped load')
    }
}


const newPage = ()=>{
    entities.clear();
}

const wrapper = document.getElementById('wrapper');



// render the react dom of the ui
const doRender = ()=>{
    ReactDom.render(React.createElement(MainUI, {tools:toolset, 
        setTool, 
        currentZoom,
        CurrentToolComp:currentToolInstance.ReactComponent,
        setZoom,
        load,
        save,
        newPage,
        requestFullscreen: ()=>wrapper.requestFullscreen()}), document.getElementById('header'))

}
doRender();



document.body.style.padding="0px";
document.body.style.padding="0px";

let dpi = window.devicePixelRatio;



window.addEventListener('keydown',(event:KeyboardEvent)=>{

    switch(event.key){
        // case ']':
        //     setSize(currentSize+2);

        // break;

        // case '[':
        //     setSize(currentSize-2);
        // break;
        

    } 
})

const fixDpi = (canvas:HTMLCanvasElement, bufferCanvas:HTMLCanvasElement) => {

    const rect = canvas.getBoundingClientRect();
    canvas.width = rect.width * dpi;
    canvas.height = rect.height * dpi;

    bufferCanvas.width = rect.width * dpi;
    bufferCanvas.height = rect.height * dpi;

    reserveBuffer.width = rect.width * dpi;
    reserveBuffer.height = rect.height * dpi;
}

const onResize =() => {
    dpi = window.devicePixelRatio;
    fixDpi(mainCanvas, bufferCanvas);
 }

window.addEventListener( "resize", onResize );

onResize();


currentToolInstance.onEquip();
// ==========================================
//          ACTUAL DRAW FUNCTION
// =========================================
const draw = () => {
    const mainCtx:CanvasRenderingContext2D = mainCanvas.getContext("2d");

    const ctx:CanvasRenderingContext2D = bufferCanvas.getContext("2d");

    const dpi = window.devicePixelRatio;

    let drawing = currentToolInstance.shouldPauseDrawing();
    // const touchArray = Object.values(currentTouches);
    // if(touchArray.length) {
    //     drawing = true;
    // }
    
    if(!drawing){
        ctx.clearRect(0,0, mainCanvas.width,mainCanvas.height)
        cached = false;
    } else{
        if(!cached) {
            const reserveCtx = reserveBuffer.getContext("2d");
            reserveCtx.clearRect(0,0, reserveBuffer.width,reserveBuffer.height);
            reserveCtx.drawImage(bufferCanvas,0,0);
            cached = true;
        } else{
            const oldComposite = mainCtx.globalCompositeOperation;
            ctx.globalCompositeOperation = "copy";
            ctx.drawImage(reserveBuffer,0,0);
            ctx.globalCompositeOperation = oldComposite;
        }
    }
    
    ctx.translate(canvasPosition.x,canvasPosition.y);
    ctx.scale(currentZoom, currentZoom)
    
    
    // redraw the entities
    // console.log('canvasPosition', canvasPosition.x, canvasPosition.y)
    if(drawing) {
        
        
    // draw all the entities as normal
    } else {
        const canvasBounds = canvasPosition.getCanvasBounds();
        entities.forEach(stroke => {

            // only draw strokes that are within the current visible canvas
            if(stroke.inBounds(canvasBounds)) {
                stroke.draw(canvasPosition, ctx);
            }
        });
    }

    if(currentToolInstance){
        currentToolInstance.draw(canvasPosition, ctx)
    }

    ctx.scale(1.0 / currentZoom , 1.0 / currentZoom)
    ctx.translate( -canvasPosition.x, -canvasPosition.y);
    

    const oldComposite = mainCtx.globalCompositeOperation;
    mainCtx.globalCompositeOperation = "copy";
    mainCtx.drawImage(bufferCanvas,0,0);
    mainCtx.globalCompositeOperation = oldComposite;


    window.requestAnimationFrame(draw)
}

window.requestAnimationFrame(draw)


