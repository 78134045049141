import * as React from 'react';
import { EntityManager } from '~entity-manager';
import { EntityBase } from '~modules/entity/entity-base';

import { CanvasPosition } from "../../CanvasPosition";

export type TouchListenerCallback = ( touches:Touch[]|TouchList, event?:TouchEvent )=>void

export type TouchListenerIterator = ( touch:Touch, touches:Touch[]|TouchList, event?:TouchEvent )=>void

export class Tool {

    protected events : [HTMLElement,  string, (event: Event )=>void][] = [];
    protected _defaultEventSubject : HTMLElement = document.body;
    protected _canvasPosition : CanvasPosition;
    protected _entityManager : EntityManager;
    protected _reserveDraw : boolean;

    constructor(defaultEventSubject, canvasPosition, entityManager){
        this._defaultEventSubject = defaultEventSubject;
        this._canvasPosition = canvasPosition;
        this._entityManager = entityManager;

    }

    public shouldPauseDrawing() : boolean{
        return this._reserveDraw;
    }

    onEquip(){

    }

    setDefaultEventSubject(subject: HTMLElement) {
        this._defaultEventSubject = subject;
    }

    onUnequip(){

        for(const [subject, eventName, callback] of this.events) {
            subject.removeEventListener( eventName, callback);
        }
        this.events = [];

    }


    // =============================================
    //          EVENT MANAGEMENT
    // ==============================================

    listen(eventName: string, callback : (event: Event )=>void, subject?:HTMLElement ) {
        if(!subject) {
            subject=this._defaultEventSubject;
        }
        this.events.push([subject, eventName, callback]);
        subject.addEventListener(eventName, callback);
        
    }

    touchListen(touchEvents: { start ?: TouchListenerCallback , change ?: TouchListenerCallback , end ?: TouchListenerCallback, cancel ?: TouchListenerCallback,
        startEach?:TouchListenerIterator, changeEach?:TouchListenerIterator, endEach?:TouchListenerIterator, cancelEach?:TouchListenerIterator  } ) {

        
        let types = {start:{event:"touchstart",
                touches: "targetTouches"}, 
            change:{event:"touchmove",
                touches: "changedTouches"},
            end:{event:"touchend",
                touches: "changedTouches"},
            cancel:{event:"touchcancel",
                touches: "changedTouches"} }
        
        Object.keys(types).forEach(key=>{
            const eventData = types[key];
            const eachKey = `${key}Each`
            if(touchEvents[key] || touchEvents[eachKey]) {
                this.listen( eventData.event, (event:Event) =>{
                    const touchEvent = event as TouchEvent
                    // touchEvent.preventDefault();
                    touchEvent.stopPropagation();

                    // free up that input thread
                    window.setTimeout(()=>{
                        const touches = touchEvent[eventData.touches];
                        if(touchEvents[key]) touchEvents[key](touches,touchEvent);
                        if(touchEvents[eachKey]) {
                            for(const touch of touches){
                                touchEvents[eachKey](touch, touches, touchEvent)
                            }
                        }
                    }, 0);


                });
            }
        })


    }



    

    // =========================================================================
    //                          DRAWING
    // ==========================================================================

    public draw(positionData:CanvasPosition , ctx : CanvasRenderingContext2D){
        if(this._reserveDraw){
            this.onReservedDraw(positionData, ctx)
        }
        this.onDraw(positionData, ctx)
    }

    protected onDraw(positionData:CanvasPosition , ctx : CanvasRenderingContext2D){

    }


    protected onReservedDraw(positionData:CanvasPosition , ctx : CanvasRenderingContext2D){

    }


    get ReactComponent() {
        return ()=><div><h1>Hello World!</h1><p>yay!</p></div>
    }


}


