import { CanvasPosition, PointData } from "../../CanvasPosition"
import { EntityBase, EntityData } from "./entity-base";
import { StrokeEntity } from "./entity-stroke";


const dpi = window.devicePixelRatio;


interface ShapeEntityData extends EntityData {
    shape?:string,
    size?:number,
}




export class ShapeEntity extends StrokeEntity{
    public x :number =0; 
    public y :number=0;
    public width   :number
    public height  :number
    protected data : ShapeEntityData;
    
    public get entityType(){
        return ShapeEntity.EntityType;
    }

    public static get EntityType(){
        return "shape";
    }

    public constructor( data :ShapeEntityData) {
        super(data );
    

    }

    protected get defaultData (){
        return {
            scale:1.0,
            shape:"circle"
        }
    } 

    public recalcBounds(){
        // process all the points
        let minX =null,
        minY=null,
        maxX=null,
        maxY=null;

        if(!this.data || !this.data.points || this.data.points.length<1) return;

        const centerPoint = this.data.points[0];
        const radiusPoint = this.data.points[1];

        const radius = Math.sqrt( (centerPoint.x - radiusPoint.x)**2 + (centerPoint.y - radiusPoint.y)**2);
        
        this.data.size =radius; 

        const strokeWidth = 10.0

        // store the bounds
        this.x = centerPoint.x - radius - strokeWidth;
        this.y = centerPoint.y - radius - strokeWidth;
        this.width = (radius + strokeWidth) * 2;
        this.height = (radius + strokeWidth) * 2;

    }


    public onDraw(positionData:CanvasPosition , ctx : CanvasRenderingContext2D) : void{

       
        const outerContext = ctx;

        let scale = 1.0;
        if(this.data.scale){
            scale = this.data.scale;

            // if(scale < 1.0) scale = 1.0;
        }

        const strokeWidth = 10 * scale;
        scale = 1.0


            // ctx = this.canvas.getContext('2d')
            // ctx.clearRect(0,0,this.canvas.width,this.canvas.height)

            // console.log('draw rect', this.x, this.y, this.width, this.height)
            ctx.strokeStyle = this.data.colors[0];
            ctx.lineWidth = strokeWidth;

            let lastPoint = null;
            // this.data.points.forEach( (point, index) =>{
            //     const circleRadius = strokeWidth;

            //     // keep track of the previous point
            //     if(lastPoint) {
            //         ctx.fillStyle = this.data.colors[0];
            //         ctx.strokeStyle = this.data.colors[0];
            //         ctx.lineWidth = circleRadius*2;

            //         // const wiggleAngle = Math.random();
            //         ctx.lineTo(point.x * (scale), (point.y )* (scale))
            //         // ctx.lineTo((point.x + Math.cos(wiggleAngle) * 10)* (scale), (point.y+ Math.sin(wiggleAngle) * 10 )* (scale))
                    
            //     } else {
            //         ctx.beginPath();
            //         ctx.moveTo( (point.x ) * (scale), (point.y )* (scale));
            //     }

                // ctx.moveTo( (point.x ) * (scale), (point.y )* (scale));

                // ctx.fillStyle = this.data.colors[0];
                // ctx.lineWidth = 0;
                // ctx.beginPath();
                // ctx.arc(point.x , point.y , circleRadius, 0,Math.PI*2);
                // ctx.closePath();
                // ctx.fill();

                // lastPoint = point;
                
            // })

            if(this.data.points && this.data.points.length >1) {
                ctx.beginPath();
                const centerPoint = this.data.points[0];
                ctx.arc(centerPoint.x , centerPoint.y , this.data.size, 0,Math.PI*2);
                ctx.stroke();

                if(this.data.colors.length>1) {
                    ctx.fillStyle=this.data.colors[1];
                    ctx.fill();
                } 

            }

        

        // outerContext.drawImage(this.canvas, this.x, this.y)

    }
}
