


export interface BoundsInterface {
    x:number,
    y:number,
    width:number,
    height:number
}

export interface PointInterface {
    x:number,
    y:number
}

export class Bounds implements BoundsInterface {
    public x: number;
    public y: number;

    public width: number;
    public height: number;

    public get bottom():number {
        return this.y + this.height;
    }

    public get right():number {
        return this.x + this.width;
    }

    constructor(x:number, y:number, width:number, height:number);
    constructor(bounds:BoundsInterface);
    constructor(point1: PointInterface, point2: PointInterface)
    
    constructor(x:number | BoundsInterface | PointInterface, y?:number |  PointInterface, width?:number, height?:number){
        [x,y,width,height] = this.getBoundsFromArgs(x,y,width,height);

        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
    }

    protected getBoundsFromArgs(x:number | BoundsInterface | PointInterface, y?:number | PointInterface, width?:number, height?:number) : [x:number,y:number,width:number,height:number]{
        
        // if both x and y are points
        if(isNaN(y as any) && isNaN(x as any)){
            const topLeft = x as PointInterface;
            const bottomRight = y as PointInterface;
            width = bottomRight.x - topLeft.x;
            height = bottomRight.y - topLeft.y;
            x = topLeft.x as number;
            y = topLeft.y as number;

        } else if(isNaN(x as any)){
            
            const bounds = x as BoundsInterface;
            x = bounds.x;
            y = bounds.y;
            width = bounds.width,
            height = bounds.height;
        }

        return [x as number,y as number,width,height];
    }

    public touchesBounds(x:number | BoundsInterface, y?:number, width?:number, height?:number) {
        [x,y,width,height] = this.getBoundsFromArgs(x,y,width,height);
        if(x>this.x+this.width) return false;
        if(y > this.y + this.height) return false;
        if(x+width < this.x) return false;
        if(y+height < this.y) return false;
        return true;
    }

    public getPortion(x:number, y:number){
        return {x:this.x + this.width * x, y:this.y + this.height * y};
        
    }

    
}