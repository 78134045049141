import { CanvasPosition } from "../../CanvasPosition";
import { TodoEntity } from "../entity/entity-todo";
import { useState} from 'react'
import { ToolColorPicker, useUIState } from "./ui/tool-ui-color";
import { Tool } from "./tool-base";
import * as React from 'react';

export class TodoTool extends Tool {

    protected _currentTouches : { [key:string]: TodoEntity}= {}
    protected _currentColor = "#000000"
    protected _fillColor = "#ff0000"
    protected _currentSize = 10;
    protected _selected:TodoEntity = null;


    constructor(defaultEventTarget, canvasPosition, entityManager){
        super(defaultEventTarget, canvasPosition, entityManager);

    }
    
    onEquip(){

        this.touchListen({startEach:(touch)=>{

            const currentZoom = this._canvasPosition.zoom;
            const currentColor = this._currentColor;
            const currentSize = this._currentSize;

            const paperPos = this._canvasPosition.paperCoords({x:touch.clientX, y:touch.clientY});

            // if we are clicking a todo thing then we leave it there
            const canvasBounds = this._canvasPosition.getCanvasBounds();
            this._entityManager.getInRect(canvasBounds).forEach((ent)=>{
                if(ent instanceof TodoEntity) {
                    const todo = ent as TodoEntity;
                    if(todo.onHandle(paperPos.x,paperPos.y, this._canvasPosition)) {
                        this._selected = todo;
                    }
                }
            })

            if(this._selected) {
                return
            }

            
            // we put in two points so that we can just update it to change the radius
            const shape = new TodoEntity({points:[paperPos,paperPos], colors:[currentColor], scale: 1.0/currentZoom});
            this._entityManager.push(shape);


            this._currentTouches[touch.identifier] = shape;
        },

        changeEach:(touch) =>{

            if(this._selected) {
                return;
            }

            const paperPos = this._canvasPosition.paperCoords({x:touch.clientX, y:touch.clientY});
            
            this._currentTouches[touch.identifier].updatePoint(paperPos);
                
            
        },

        endEach:(touch) =>{

            const paperPos = this._canvasPosition.paperCoords({x:touch.clientX, y:touch.clientY});
            if(this._selected) {
                
                if(this._selected.onHandle(paperPos.x,paperPos.y, this._canvasPosition)) {
                    this._selected.toggle();
                }

                this._selected = null;
                return;
            }

            this._currentTouches[touch.identifier].updatePoint(paperPos);

                this._currentTouches[touch.identifier].setState(1);
                
                const currentStroke = this._currentTouches[touch.identifier];
                const bounds = currentStroke.getBounds();
     
                this._currentTouches[touch.identifier].setSubject(this._entityManager.getInRect(bounds.x,bounds.y, bounds.width,bounds.height))
                delete this._currentTouches[touch.identifier];
        }
    } )

    }


    public get ReactComponent(){


        return ()=>{


            const StrokeColor = useUIState(this._currentColor, (newColor) =>{
                this._currentColor = newColor;
            })

            const FillColor = useUIState(this._fillColor, (newColor) =>{
                this._fillColor = newColor;
            })

            return <React.Fragment>
                    <StrokeColor label="Stroke Color" />
                    <FillColor label="Fill Color" />
                </React.Fragment>
            
        }
    }
}
