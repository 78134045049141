
import { EntityData } from "./entity-base"
import { CanvasEntity } from "./entity-canvas";
import { ShapeEntity } from "./entity-shape";
import { StrokeEntity } from "./entity-stroke";
import { TodoEntity } from "./entity-todo";

const typeList = [ShapeEntity,StrokeEntity,TodoEntity, CanvasEntity]

const typeMap = {};
typeList.forEach(type =>{
    typeMap[type.EntityType] = type;
});

console.log({ShapeEntity})
export const dataToEntity  = ( json : {id:number, type:string, data:EntityData}) =>{
    if(!new typeMap[json.type]) return null;
    return new typeMap[json.type](json.data)

}