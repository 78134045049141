import { BoundsInterface } from "~bounds";
import { CanvasPosition, PointData } from "../../CanvasPosition"
import { EntityBase, EntityData } from "./entity-base";
import { StrokeEntity } from "./entity-stroke";


const dpi = window.devicePixelRatio;


interface CanvasEntityData extends EntityData {

}



export class CanvasEntity extends StrokeEntity{


    
    public x :number =0; 
    public y :number=0;
    public cols:number = 3;
    public rows:number = 2;

    public width   : number
    public height  : number
    protected data : CanvasEntityData;
    protected _needUpdate : boolean = true;

    public get entityType(){
        return CanvasEntity.EntityType;
    }

    public static get EntityType(){
        return "canvas";
    }

    // protected _currentState : number = 0;

    protected set _currentState(newState:number){
        if(!this.data ) return;
        this.data.state = newState;
    }

    protected get _currentState(){
        if(!this.data || !this.data.state ) return 0;
        return this.data.state;
    }


    public constructor( data :CanvasEntityData) {
        super(data );
    
        if(data && data.state) {
            this._currentState=data.state;
        }

        // canvas 
        this.canvas = document.createElement('canvas');
        
    }


    protected get defaultData (){
        return {
            scale:1.0,
            state:0
        }
    } 

    public recalcBounds(){
        // process all the points
        let minX =null,
        minY=null,
        maxX=null,
        maxY=null;

        if(!this.data 
            || !this.data.points 
            || this.data.points.length<2) return;

        const topLeft = this.data.points[0];
        const bottomRight = this.data.points[1];
        
        this.data.size = Math.max( Math.abs(bottomRight.x - topLeft.x), Math.abs(bottomRight.y - topLeft.y) ); 
        this.data.points.forEach(item => {
            if(maxX===null || item.x > maxX) maxX = item.x;
            if(minX===null || item.x < minX) minX = item.x;

            if(maxY===null || item.y > maxY) maxY = item.y;
            if(minY===null || item.y < minY) minY = item.y;
        });

        // const strokeWidth = this.data.size;

        // store the bounds
        this.x = minX ;
        this.y = minY ;
        this.width = maxX - minX ;
        this.height = maxY - minY ;

        this.canvas.width = this.width;
        this.canvas.height = this.height;
        this._needUpdate = true;

    }
 

    public get currentState(){
        return this._currentState;

    }

    public setState(newState:number){
        
        switch(this._currentState) {
            case 0:
        }

        this._currentState = newState;

        switch(this._currentState) {
            case 1:

        }
    }



    public toggle(){
        if(this._currentState==1){
            this.setState(2);
            return;
        }

        if(this._currentState==2){
            this.setState(1);
            return;
        }
    }

    public updateSticker(){
        this.recalcBounds();
        this._needUpdate = true;
    }



    public draw(positionData:CanvasPosition , ctx : CanvasRenderingContext2D) : void{

       
        const outerContext = ctx;

        let scale = 1.0;
        if(this.data.scale){
            scale = this.data.scale;

            // if(scale < 1.0) scale = 1.0;
        }

        const strokeWidth = 10 * scale;
        scale = 1.0


        // ctx = this.canvas.getContext('2d')
        // ctx.clearRect(0,0,this.canvas.width,this.canvas.height)

        // console.log('draw rect', this.x, this.y, this.width, this.height)
        ctx.strokeStyle = this.data.colors[0];
        ctx.lineWidth = strokeWidth;

        let lastPoint = null;

        const checkboxSize = 40 * positionData.dpi;
        const padding = 8;

        // if it has a shape then draw
        if(this.data.points && this.data.points.length >1) {

            //if we need to update our canvas then do it
            if(this.canvas.width >0 && this.canvas.height >0) {
                const bounds = this.getBounds();
                if(this._needUpdate) {
                    
                    this.canvas.getContext('2d').drawImage(ctx.canvas, 
                        (bounds.x* positionData.zoom + positionData.x) , (bounds.y* positionData.zoom + positionData.y) , bounds.width * positionData.zoom , bounds.height * positionData.zoom, 
                        0,0,bounds.width,bounds.height)
                    this._needUpdate = false;
                }
            
                let frame = Math.round((Date.now()/300)) % (this.cols*this.rows)
                let frameX = frame % this.cols;
                let frameY = Math.floor(frame / this.cols);

                const frameWidth = (bounds.width / this.cols);
                const frameHeight = (bounds.height / this.rows);

                ctx.drawImage(this.canvas, frameX*frameWidth, frameY*frameHeight, frameWidth, frameHeight,
                    this.x + bounds.width + 50, this.y, frameWidth ,frameHeight)
            }
            ctx.beginPath();
            ctx.lineWidth = 4;

            const topLeft = this.data.points[0];
            const bottomRight = this.data.points[1];
            
            ctx.strokeStyle = this.data.colors[0];
            ctx.rect(topLeft.x , topLeft.y , bottomRight.x - topLeft.x , bottomRight.y - topLeft.y );

            const bounds = this.getBounds();
            for(let i=0;i<this.cols-1;i++){
                const point = bounds.getPortion(1.0/this.cols * (i+1),0)
                ctx.moveTo(point.x, bounds.y);
                ctx.lineTo(point.x, bounds.bottom);
            }

            for(let i=0;i<this.rows;i++){
                const point = bounds.getPortion(0, 1.0/this.rows * (i+1))
                ctx.moveTo(point.x, point.y);
                ctx.lineTo(bounds.right, point.y);
            }

            ctx.stroke();

            // draw the image
            
            
            

        }

        

        // outerContext.drawImage(this.canvas, this.x, this.y)

    }

    getTotalBounds(boundsList: BoundsInterface[]):{x:number, y:number, x2:number, y2:number} {
        const startingPoint = boundsList[0];
        
        
        const totalBounds = {x:startingPoint.x, y:startingPoint.y, x2:startingPoint.x, y2:startingPoint.y}
        boundsList.forEach(bounds =>{
            
            if(totalBounds.x > bounds.x) totalBounds.x = bounds.x;
            if(totalBounds.y > bounds.y) totalBounds.y = bounds.y;
            if(totalBounds.x2 < bounds.x + bounds.width) totalBounds.x2 = bounds.x + bounds.width;
            if(totalBounds.y2 < bounds.y + bounds.height) totalBounds.y2 = bounds.y + bounds.height;
           
        })

        return totalBounds;
        
    }
}
