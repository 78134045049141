import { BoundsInterface } from "~bounds";
import { EntityManager } from "~entity-manager";
import { CanvasPosition } from "../../CanvasPosition";



export interface EntityData {
    colors?: string[],
    points:  any[],
    size?: number,
    scale?: number
    type?:string
    state?:number
}

// may or may not figure out something else
let counter =1;

export class EntityBase {
    
    private _id :number = 1;
    protected data    : EntityData
    protected _relationships: { [key:string] : EntityBase | EntityBase[]} = {};
    public static DefaultEntityManager:EntityManager;
    
    public get entityType(){
        return EntityBase.EntityType;
    }

    public static get EntityType(){
        return "base";
    }

    protected get defaultData (){
        
        return {
            scale:1.0,
        }
    }

    public setId(newId:number){
        if(newId > counter) counter=newId+1;
        let oldId = this._id;
        this._id = newId;
        EntityBase.DefaultEntityManager.updateId(oldId);
        
    }

    public get id():number{
        return this._id;
    }

    constructor(data :EntityData) {
        this.data = {...this.defaultData, ...data};
        this._id = counter++;
    }

    public getBounds(){
        return {x:0,y:0,width:0, height:0}
    }

    public inBounds(x:number | BoundsInterface, y?:number, width?:number, height?:number){
        return false;
    }

    public recalcBounds(){

    }

    public toJson(){


        let relationships = {};
        Object.keys(this._relationships).forEach(key =>{
            let relation = this._relationships[key];
            if(Array.isArray(relation) ){
                relationships[key] = relation.map(item => item.id)
            } else {
                relationships[key] = relation.id;
            }
            
        })

        const retobj = {
            id: this.id,
            data: this.data,
            type: this.entityType,
            relationships
        }

        return retobj
    }


    public draw(positionData:CanvasPosition , ctx : CanvasRenderingContext2D) : void{
        
        this.debugDraw(positionData, ctx);
        this.onDraw(positionData, ctx)

    }

    public debugDraw(positionData:CanvasPosition , ctx : CanvasRenderingContext2D){
        return;
        ctx.strokeStyle = "rgb(255, .9, 0, 0.5)"
        ctx.lineWidth = 3
        ctx.beginPath();
        const bounds = this.getBounds();
        ctx.rect(bounds.x , bounds.y, bounds.width, bounds.height);
        ctx.stroke();
    }

    public onDraw(positionData:CanvasPosition , ctx : CanvasRenderingContext2D) : void{

    }


    // =========================================================================
    //                          RELATIONSHIPS
    // ==========================================================================
    public addRelationship(name:string, entity: EntityBase|EntityBase[]) {
        this._relationships[name] = entity;
        this.recalcBounds();
    }

    public getRelationship(name:string) : EntityBase|EntityBase[]{
        if(!this._relationships[name]) return null;
        return this._relationships[name]; 
    }

}