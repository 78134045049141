import { CanvasPosition } from "../../CanvasPosition";
import { ShapeEntity } from "../entity/entity-shape";
import { useState} from 'react'
import { ToolColorPicker, useUIState } from "./ui/tool-ui-color";
import { Tool } from "./tool-base";
import * as React from 'react';
import { CanvasEntity } from "~modules/entity/entity-canvas";

export class StickerTool extends Tool {

    protected _currentTouches : { [key:string]: CanvasEntity}= {}
    protected _currentColor = "#000000"
    protected _fillColor = "#ff0000"
    protected _currentSize = 10;


    constructor(defaultEventTarget, canvasPosition, entityManager){
        super(defaultEventTarget, canvasPosition, entityManager);

    }
    
    onEquip(){

        this.touchListen({startEach:(touch)=>{

            const currentZoom = this._canvasPosition.zoom;
            const currentColor = this._currentColor;
            const currentSize = this._currentSize;
            const fillColor = this._fillColor;

            const paperPos = this._canvasPosition.paperCoords({x:touch.clientX, y:touch.clientY});

            // we put in two points so that we can just update it to change the radius
            const shape = new CanvasEntity({points:[paperPos,paperPos], colors:[currentColor, fillColor], scale: 1.0/currentZoom});
            this._entityManager.push(shape);


            this._currentTouches[touch.identifier] = shape;
        },

        changeEach:(touch) =>{

            const paperPos = this._canvasPosition.paperCoords({x:touch.clientX, y:touch.clientY});
            
            this._currentTouches[touch.identifier].updatePoint(paperPos);
            
        },

        endEach:(touch) =>{
                this._currentTouches[touch.identifier].addPoint(this._canvasPosition.paperCoords({x:touch.clientX, y:touch.clientY}))
                this._currentTouches[touch.identifier].updateSticker();
                delete this._currentTouches[touch.identifier];
        }
    } )

    }


    public get ReactComponent(){


        return ()=>{


            const StrokeColor = useUIState(this._currentColor, (newColor) =>{
                this._currentColor = newColor;
            })

            const FillColor = useUIState(this._fillColor, (newColor) =>{
                this._fillColor = newColor;
            })

            return <React.Fragment>
                    <StrokeColor label="Stroke Color" />
                    Sticker
                </React.Fragment>
            
        }
    }
}
