import {useState} from 'react';
import * as React from 'react';
import * as ColorPicker from 'react-color'

export const ToolColorPicker = (props)=>{

    const {color, setColor, label="Color"} = props;

    const [ pickingColor, setPickingColor ] = useState(false);
    const [ tempColor, setTempColor ] = useState(color);

    // console.log('tempcolor', tempColor)

    const onChangeColor = (colorData, event:TouchEvent) =>{
        console.log('event', event)
        // event.stopPropagation();
        // event.cancelBubble = true;
        setTempColor(colorData.hex)
    }

    const pickColor = ()=>{
        setTempColor(color)
        setPickingColor(true);
    }

    const endPickColor = (newColor?)=>{
        setColor(tempColor);
        console.log('endpicker')
        // 
        setPickingColor(false);
    }


    let colorSection = null;
    if(pickingColor) {

        colorSection = (<React.Fragment>
                    <div onClick={endPickColor} style={{position:"absolute", left:0, top:0, bottom:0, right:0, zIndex:10, width:'100%', background:tempColor }}></div> 
                        <div style={{position:"relative",zIndex:20, height:40, overflow:'visible'}}>
                            <ColorPicker.SketchPicker key={"colorpicker"} color={tempColor} style={{zIndex:20, position:"fixed"}} 
                                onChange={onChangeColor} 
                                disableAlpha={true} /> 
                        </div>
                    </React.Fragment>)
    }


    return  <div style={{display:"inline-block", height:40}}>
        {label}:<div style={{display:"inline-block", background:color}} onClick={pickColor}> 
        {color}  </div>{colorSection}
                </div>
}




export const useUIState = (defaultValue, setter) => {
    const [ tempVal, setTempVal] = useState(defaultValue);

    const onUpdate = (newVal)=>{
        setTempVal(newVal);
        setter(newVal)
    }

    return (props)=><ToolColorPicker color={tempVal} setColor={onUpdate} {...props} />
}