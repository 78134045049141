import * as React from 'react';
import { GoogleLogin } from 'react-google-login';
import Axios from 'axios';
import { Drawer } from '~/modules/common/drawer/common-drawer'

const useState = React.useState;


export const MainUI = ({tools,
    setTool,
    save,
    requestFullscreen,
    load,
    setZoom,
    currentZoom,
    newPage,
    CurrentToolComp}) =>{

    const [showLogin, setShowLogin] = useState(false)
    const [profileObj, setProfileObj] = useState(null)
    const [accessToken, setAccessToken] = useState(null);
    const [idToken, setIdToken] = useState(null);
    const [pageName, setPageName] = useState("New Page | "+ Date.now());
    const [drawerOpen, setDrawerOpen]  = useState(false);
    const [pageList, setPageList] = useState([])
    const [currentPage, setCurrentPage] = useState(null)
    

    const [userData, setUserData] = useState(null)
    const isLoggedIn = ()=> (userData!==null)

    const sendSave = async(data:any) => {
        const config = {
            withCredentials:true,
        };
        
        const bodyParameters = {
        pageName,
        function:'save',
        entities: data,
        };

        if(currentPage && currentPage.id) {
            bodyParameters['id'] = currentPage.id;
        }
        
        console.log('credentials')
        Axios.post( 
           
            'https://api.notebook.unbound.services/',
            bodyParameters,
            config
        ).then(console.log).catch(console.log);
    }

    const getList = () => {
        const config = {
            withCredentials:true,
        };
        
        const bodyParameters = {
        function:'list',
        };
        console.log('credentials')
        Axios.post(
            'https://api.notebook.unbound.services/',
            bodyParameters,
            config
        ).then((result)=>{
            setPageList(result.data);
        }).catch(console.log);
    }

    const getPageData = async (pageId) => {
        const config = {
            withCredentials:true,
        };
        
        const bodyParameters = {
            function:'load',
            pageId
        };
        console.log('credentials')
        return (await Axios.post(
            'https://api.notebook.unbound.services/',
            bodyParameters,
            config
        )).data
    }

    const doLogin = (tokenId:any) => {
        const config = {
            withCredentials:true,
        };
        
        const bodyParameters = {
        function:'login',
        idToken:tokenId
        };
        console.log('credentials')
        Axios.post( 
           
            'https://api.notebook.unbound.services/',
            bodyParameters,
            config
        ).then(result=> {
            setUserData(result.data);
            console.log('result from login', result)
            }
        ).catch(console.log);
    }

    const loadPage = async(id)=>{
        const pageData = await getPageData(id);
        load(pageData);
        setCurrentPage(pageData)
        setPageName(pageData.pageName)
        console.log('pageData', pageData)
    }

    let loginStyling : any= {display:'none', verticalAlign:'top', overflow:'hidden', height:"40px", right:0, position: 'absolute', zIndex:10};
    if(showLogin){
        loginStyling = {...loginStyling, overflow:"visible", height:"100px", display:'inline-block', background:'white'}
    }

    let profile = null;
    if(profileObj) {
        profile =<img src={profileObj.imageUrl} style={{width:40, height:40, display:"inline-block", borderRadius:"100%"}} />
    }

    const responseGoogle = (response) => {
        console.log('profileObj', response.profileObj)
        setProfileObj(response.profileObj);
        setAccessToken(response.accessToken);
        setIdToken(response.tokenId);
        // login with the server
        doLogin(response.tokenId)

        }

    const onOpenDrawer = ()=>{
        getList();
        setDrawerOpen(true)
    }


    // take care of debug local saving/loading for troubleshooting loading/saving
    const localLoad = () =>{
        const strokeDatum = JSON.parse(localStorage.getItem("page"));
        console.log('did local load', strokeDatum);
        load(strokeDatum);
    }

    let localSaveSection=null;
    if(window.location.href=='https://localhost:1234/') {
        localSaveSection = <React.Fragment>
            <button onClick={()=>save(console.log)}>Local Save</button>
            <button onClick={localLoad}>Local Load</button>
            </React.Fragment>
    }

    

    return <div key={"main-root"}>
        Tool: <select onChange={ (event)=>setTool(event.target.value)} >
            
            {tools.map( (tool, toolIndex)=>(<option key={toolIndex} value={toolIndex}>{tool}</option>))}
            </select>
            
            <span>
                <button onClick={()=>setZoom(Math.min(currentZoom+.1, 2.0))}>+</button>
                {Math.round(currentZoom * 100)}% 
                <button onClick={()=>setZoom( Math.max(currentZoom-.1, .1))}>-</button>
            </span> 
            | <span onClick={requestFullscreen}>[+]</span> |  
            <CurrentToolComp />
            <button onClick={()=>{setShowLogin(!showLogin)}}>Login</button>
            <div style={loginStyling}>
                <GoogleLogin
                clientId="158999154899-sru3o6srioh9vtt89tscve8q9m9up4lm.apps.googleusercontent.com"
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                redirectUri={window.location.href.substr(0,window.location.href.length - 1 )}
                isSignedIn={true}
                uxMode={"redirect"}
                cookiePolicy={"single_host_origin"}/>
                {profile}
            </div>
            <Drawer open={drawerOpen} onClose={()=>setDrawerOpen(false)}>
                <h1>Pages</h1>
                <button onClick={()=>getList()}>List</button>
                <button onClick={()=>save(sendSave)}>Save</button>
                {localSaveSection}
                <button onClick={()=>newPage()}>New Page</button>
                <ul>
                    {pageList.map(item=><li style={{color:"white", fontFamily:"arial", fontSize:"1.1em"}} key={item.id} onClick={()=>loadPage(item.id)}>{item.pageName}</li>)}
                </ul>
            </Drawer>
            <button onClick={onOpenDrawer}>Pages</button>
            <input type='text' style={{width:"100px"}} onChange={ ({target}) => setPageName(target.value)} value={pageName} />
    </div>
}


// https://developers.google.com/identity/protocols/oauth2/web-server#tokenrevoke