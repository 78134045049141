import { CanvasPosition, PointData } from "../../CanvasPosition";
import { ShapeEntity } from "../entity/entity-shape";
import { useState} from 'react'
import { ToolColorPicker, useUIState } from "./ui/tool-ui-color";
import { Tool } from "./tool-base";
import * as React from 'react';

export class MoveTool extends Tool {

    protected _currentAnchor : PointData = null;

    constructor(defaultEventTarget, canvasPosition, entityManager){
        super(defaultEventTarget, canvasPosition, entityManager);

    }
    
    onEquip(){



        this.touchListen({start:(touches, touchEvent)=>{

            this._currentAnchor = {x:0,y:0}
            for( const touch of touchEvent.touches ) {
                const paperPos = this._canvasPosition.paperCoords({x:touch.clientX, y:touch.clientY});
                this._currentAnchor.x += paperPos.x;
                this._currentAnchor.y += paperPos.y;
            }
            this._currentAnchor.x /= 1.0*touchEvent.touches.length;
            this._currentAnchor.y /= 1.0*touchEvent.touches.length;

        },

        change:(touches, touchEvent) =>{

    
            const center = {x:0,y:0}
            for( const touch of touchEvent.touches ) {
                const paperPos = this._canvasPosition.paperCoords({x:touch.clientX, y:touch.clientY});
                center.x += paperPos.x;
                center.y += paperPos.y;
            }
            center.x /= touchEvent.touches.length;
            center.y /= touchEvent.touches.length;

            const zoom = this._canvasPosition.zoom;

            this._canvasPosition.translate({x: (center.x - this._currentAnchor.x)  * zoom, y: (center.y - this._currentAnchor.y) * zoom } )


            
        },

        end:(touches, touchEvent) =>{
            this._currentAnchor = {x:0,y:0}
            
            if(touchEvent.touches.length ==0) return;

            for( const touch of touchEvent.touches ) {
                const paperPos = this._canvasPosition.paperCoords({x:touch.clientX, y:touch.clientY});
                this._currentAnchor.x += paperPos.x;
                this._currentAnchor.y += paperPos.y;
            }
            
            this._currentAnchor.x /= 1.0*touchEvent.touches.length;
            this._currentAnchor.y /= touchEvent.touches.length;

        }
    } )

    }


    public get ReactComponent(){


        return ()=>{


            return <React.Fragment>
                    <strong>hello</strong>
                </React.Fragment>
            
        }
    }
}
