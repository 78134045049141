import { EntityBase } from "~modules/entity/entity-base";



export class EntityManager {
    
    protected _entities: {[key:number]:EntityBase} = {}; 

    constructor(items?: Array<EntityBase>) {
        
    }
    static create(): EntityManager {
        return Object.create(EntityManager.prototype);
    }

    public getInRect(x:number | {x:number, y:number, width:number, height:number}, y?:number, width?:number, height?:number){
        let xCoord : number;
        if(isNaN(x as any)) {
            x = x as {x:number, y:number, width:number, height:number};
            width = x.width;
            height = x.height;
            y = x.y;
            xCoord = x.x;
        } else {
            xCoord = x as number;
        }
        
        let returnSet = [];
        this.forEach((ent,index)=>{
            if(ent.inBounds(xCoord,y,width,height)){
                returnSet.push(ent);
            }
        })
        return returnSet;
    }

    public push(ent:EntityBase) {
        this._entities[ent.id] = ent
    }

    public forEach(callback:(ent:EntityBase, index?:number) => void){
        Object.values(this._entities).forEach(callback);
    }

    public getById(id:number){
        if(this._entities[id]){
            return this._entities[id];
        }
        return null;
    }

    public clear(){
        this._entities = {};
    }

    public delete(id:number) {
        if(this._entities[id]){
            delete this._entities[id]
        }
    }

    public updateId(oldId){
        if(this._entities[oldId] && this._entities[oldId].id!==oldId){
            this.entities[this._entities[oldId].id] = this._entities[oldId];
            delete this.entities[oldId];
        } 
    }

    public get entities():EntityBase[] {
        return Object.values(this._entities);
    }
}
