import * as React from 'react'
import { CanvasPosition } from "../../CanvasPosition";
import { StrokeEntity } from "../entity/entity-stroke";
import { useState} from 'react'

import { useUIState } from '~/modules/tool/ui/tool-ui-color'
import { Tool } from "./tool-base";



export class BrushTool extends Tool {

    protected _strokes:StrokeEntity[] = [];
    protected _currentTouches : { [key:string]: StrokeEntity}= {}
    protected _currentColor = "#000000"
    protected _currentSize = 4;

    constructor(defaultEventTarget, canvasPosition, entityManager){
        super(defaultEventTarget, canvasPosition, entityManager);

    }
    
    onEquip(){

        this.touchListen({startEach:(touch)=>{

            const currentZoom = this._canvasPosition.zoom;
            const currentColor = this._currentColor;
            const currentSize = this._currentSize;

            const paperPos = this._canvasPosition.paperCoords({x:touch.clientX, y:touch.clientY});

            const stroke = new StrokeEntity({points:[paperPos, paperPos], colors:[currentColor], size: currentSize, scale: 1.0/currentZoom});
            this._entityManager.push(stroke);

            this._reserveDraw=true;


            this._currentTouches[touch.identifier] = stroke;
        },

        changeEach:(touch) =>{

            const paperPos = this._canvasPosition.paperCoords({x:touch.clientX, y:touch.clientY}); // 4
            
            
            const lastTouch = this._currentTouches[touch.identifier];
            const lastPoint = lastTouch.getPoint(-1); // 2
            const prevPoint = lastTouch.getPoint(-2); // 1 3

            // angle = arccos[(xa * xb + ya * yb) / (√(xa2 + ya2) * √(xb2 + yb2))]
            //angle = arccos[((x2 - x1) * (x4 - x3) + (y2 - y1) * (y4 - y3)) / (√((x2 - x1)2 + (y2 - y1)2) * √((x4 - x3)2 + (y4 - y3)2))]
            if(lastPoint && prevPoint){

                if( (lastPoint.x == prevPoint.x && lastPoint.y == prevPoint.y)
                || (paperPos.x == prevPoint.x && paperPos.y == prevPoint.y)) {
                    lastTouch.updatePoint(paperPos);
                    return;
                }


                const currentVec = {y:lastPoint.y - prevPoint.y, x:lastPoint.x - prevPoint.x};
                const newVec = {y: paperPos.y - prevPoint.y, x:paperPos.x - prevPoint.x};

                const len = (vec) => Math.sqrt(vec.x**2 + vec.y**2);


                const prevLength = len(currentVec)
                const length = len(newVec);

                // const numerator = (lastPoint.x - prevPoint.x) * (paperPos.x - prevPoint.x) + (lastPoint.y - prevPoint.y) * (paperPos.y - prevPoint.y);

                const numerator = (currentVec.x*newVec.x + currentVec.y*newVec.y);
                const denom =  ( Math.sqrt(currentVec.x**2.0 + currentVec.y**2.0) * Math.sqrt(newVec.x**2.0 + newVec.y**2.0));
                const fraction = Math.max(Math.min(numerator/denom, 1.0), -1);
                const angle = Math.acos( fraction)
                // const angle = Math.abs(Math.atan2(currentVec.y, currentVec.x) - Math.atan2(newVec.y, newVec.x))
                const score = Math.abs(angle * length);


                if(isNaN(angle)) alert(`${angle} ${currentVec.x} ${currentVec.y} ${newVec.x} ${newVec.y} | ${prevLength}, ${length} paperpos:${numerator}, ${denom}, ${fraction}`)
                
        
                if(  length > 8.0 / this._canvasPosition.zoom && (length<=prevLength || Math.abs(angle) > 0.003) ){
                    lastTouch.addPoint(paperPos);
                    // console.log('length addPoint', length, angle)

                } else {
                    lastTouch.updatePoint(paperPos)
                    // console.log('length last else', length, angle)
                }
                
                // if( angle < .2 ) {
                    
                // } else if(length>2.0*this._canvasPosition.dpi) {
                    
                // }
                
            } else{
                lastTouch.addPoint(paperPos);
            }
            
        },

        endEach:(touch) =>{
                this._currentTouches[touch.identifier].addPoint(this._canvasPosition.paperCoords({x:touch.clientX, y:touch.clientY}))
                delete this._currentTouches[touch.identifier];
                if(!this._currentTouches.length) {
                    this._reserveDraw=false;
                }
        },
        cancelEach: (touch)=>{
            const stroke = this._currentTouches[touch.identifier];
            this._entityManager.delete(stroke.id);
            delete this._currentTouches[touch.identifier];
            console.log('canceled')
        }  
    })



    }


    onReservedDraw(positionData:CanvasPosition , ctx : CanvasRenderingContext2D){
        Object.values(this._currentTouches).forEach(stroke => {
            stroke.draw(positionData, ctx);
        });
    }




    public get ReactComponent(){

        return ()=>{

            const [ tempSize, setTempSize ] = useState(this._currentSize);

            const setSize = (newSize)=>{
                setTempSize(newSize);
                this._currentSize = newSize;
            }
            

            const ColorPicker = useUIState(this._currentColor, (newColor=>this._currentColor = newColor))

            return  <div style={{display:"inline-block"}}>| <ColorPicker label="Color" />
            | <span><button onClick={()=>setSize(tempSize+1)}>+</button>{tempSize} <button onClick={()=>setSize( Math.max(tempSize-1, 1))}>-</button></span> 
            </div>

            
        }
    }
}
